import React from 'react';
import CardNavigator from "../components/CardNavigator";


const Create = () => {
  const options = [
    {type: 'Game', url: '/create/game', img: '/static/game.png'},
    {type: 'Story', url: '/create/story', img: '/static/story.png'},
    {type: 'Riddle', url: '/create/riddle', img: '/static/riddle.png'},
    {type: 'Quiz', url: '/create/quiz', img: '/static/quiz.png'},
    {type: 'Image', url: '/create/image', img: '/static/image.png', disabled: true},
    {type: 'Encyclopedia', url: '/explore/encyclopedia', img: '/static/encyclopedia.png', disabled: true},
  ];

  return (
    <CardNavigator
      title="Click to create..."
      options={options}
    />
  )
}

export default Create;
