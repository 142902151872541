import React, {useState} from 'react';
import {
  Box,
  Button,
  TextField,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  CircularProgress
} from '@mui/material';
import api from "../utils/api";
import apiConfig from "../configs/api.json";
import TextFieldWithSubmissions from "./TextFieldWithSubmissions";

const OptionsDropdown = ({id, title, options, currentValue, onChange}) => {
  return (
    <FormControl fullWidth>
      <InputLabel id={`${id}-label-id`}>{title}</InputLabel>
      <Select
        labelId={`${id}-label-id`}
        id={id}
        value={currentValue}
        label={title}
        onChange={onChange}
      >
        {options.map((option) => (
          <MenuItem value={option.value}>{option.title}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

// Changed the component name to GenericForm and added the required props
function GenericForm({
  title, optionsConfig, appUrl, redirectUrl, children
}) {
  const [formValues, setFormValues] = useState(
    Object.keys(optionsConfig).reduce((acc, key) => {
      acc[key] = optionsConfig[key].defaultValue;
      return acc;
    }, {})
  );
  // Add loading and error states in the parent component
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);

  const handleChange = (key) => (e) => {
    // const value = Array.isArray(e) ? e : e.target.value;
    setFormValues({
      ...formValues,
      [key]: e.target.value,
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    api
      .post(`${apiConfig.serverUrl}${appUrl}`, formValues)
      .then((response) => {
        setData(response.data);
        setLoading(false);
        setError(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
      });
  };
  console.log(formValues);
  return (
    <>
      <Stack spacing={2} m={10}>
        <Typography paragraph>{title}</Typography>
        {Object.entries(optionsConfig).map(([key, config]) => {
          if (config.type === 'textfield') {
            return (
              <TextField
                fullWidth
                value={formValues[key]}
                onChange={handleChange(key)}
                {...config}
              />
            );
          } else if (config.type === 'dropdown') {
            return (
              <OptionsDropdown
                currentValue={formValues[key]}
                onChange={handleChange(key)}
                {...config}
              />
            );
          } else if (config.type === 'textfieldwithsubmissions') {
            return (
              <TextFieldWithSubmissions
                fullWidth
                onChange={handleChange(key)}
                {...config}
                />
            )
          }
          return null;
        })}
        <Button
          size="large"
          variant={loading ? "outlined" : "contained"}
          onClick={handleSubmit}
          {...(error ? {color: 'error'} : {})}
          disabled={loading}
        >
          {loading ? (
            <Box display="flex" gap={2}>
              <CircularProgress size={24}/>
              <Typography variant="button" display="block" gutterBottom>
                Creating (can wait 1-2 minutes)...
              </Typography>
            </Box>
          ) : error ? "Error. Try again later": "Create"}
        </Button>
        {data && children({data, appUrl, redirectUrl})}
      </Stack>
    </>
  );
}

export default GenericForm;