import React, { useState, useCallback } from 'react';
import {
  TextField,
  Paper,
  List,
  ListItem,
  Typography,
  Card,
  CardContent,
  Grid,
  Box,
  CardHeader,
  CardActions, IconButton
} from '@mui/material';
import moment from "moment";
import {Favorite as FavoriteIcon} from "@mui/icons-material";


// Custom TextField component
const TextFieldWithSubmissions = ({ onChange, ...textFieldOptions }) => {
  const [value, setValue] = useState("");
  const [submissions, setSubmissions] = useState([]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(value.trim() !== "") {
      const newSubmissions = [...submissions, value];
      setSubmissions(newSubmissions);
      setValue(""); // Reset the field after submission
      onChange({target: {value: newSubmissions}});
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <TextField
          value={value}
          onChange={handleChange}
          {...textFieldOptions}
        />
      </form>
      <Grid container m={2} spacing={2} justifyContent="center">
        {submissions.map((submission, index) => (
            <Paper variant="outlined" sx={{ p: 2, m: 1, borderRadius: '1rem'}}>
              <Typography>{submission}</Typography>
            </Paper>
        ))}
      </Grid>
    </div>
  );
  //   return (
  //   <div>
  //     <form onSubmit={handleSubmit}>
  //       <TextField
  //         value={value}
  //         onChange={handleChange}
  //         {...textFieldOptions}
  //       />
  //     </form>
  //     <Grid container spacing={4}>
  //       {submissions.map((submission, index) => (
  //         <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
  //           <Card>
  //             <CardContent>
  //               <Typography variant="h5" component="div">
  //                 {submission}
  //               </Typography>
  //             </CardContent>
  //           </Card>
  //         </Grid>
  //       ))}
  //     </Grid>
  //   </div>
  // );
};

export default TextFieldWithSubmissions;
