// api.js
import axios from 'axios';
import { csrftoken } from './csrftoken';

const api = axios.create({
  baseURL: '', // or your Django API base URL
  headers: {
    'Content-Type': 'application/json',
    'X-CSRFToken': csrftoken,
  },
});

export default api;