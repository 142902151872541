import React from "react";
import {CardActions, IconButton, Tooltip} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import {useNavigate} from "react-router-dom";
import api from "../utils/api";
import apiConfig from "../configs/api.json";

function ShareButton({patchUrl, redirectUrl}) {
  const navigate = useNavigate();
  const handleShare = (e) => {
    api
      .patch(`${apiConfig.serverUrl}${patchUrl}`, {
        shared: true
      })
      .then(response => {
        navigate(`${redirectUrl}`);
      })
      .catch(error => alert(error))
  }

  return (
    <>
      <CardActions>
        <Tooltip title="Share">
          <IconButton onClick={handleShare}>
            <ShareIcon sx={{'&:hover': {color: 'blue'}}}/>
          </IconButton>
        </Tooltip>
      </CardActions>
    </>
  )
}

export default ShareButton;
