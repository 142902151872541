import React from 'react';
import {Box, Card, CardActionArea, CardContent, CardMedia, Grid, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import textsData from "../configs/texts.json";

const CardNavigator = ({title, options}) => {
  const navigate = useNavigate();

  const handleClick = (url) => {
    navigate(url);
  };

  return (
    <>
      <Typography
        variant="h5"
        component="h2"
        gutterBottom
        align="center"
        sx={{
          marginTop: '2rem',
          marginBottom: '2rem',
          fontWeight: 'bold',
          color: 'primary.main',
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          minHeight: '100vh',
          width: '100%',
          backgroundColor: 'background.default',
          paddingTop: '2rem',
        }}
      >

        <Grid container spacing={4}
              justifyContent="center"
          // alignItems="center"
              sx={{maxWidth: '90%', margin: '0 auto'}}>
          {options.map((option) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={option.type}>
              <Card sx={{opacity: option.disabled ? 0.6 : 1}}>
                <CardActionArea
                  onClick={() => handleClick(option.url)}
                  sx={{pointerEvents: option.disabled ? "none" : "auto"}}
                >
                  <CardMedia
                    component="img"
                    height="300"
                    image={option.img}
                    alt={option.type}
                    // sx={{
                    //   objectFit: 'contain', // Add this line
                    // }}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {option.type}
                    </Typography>
                  </CardContent>
                  {option.disabled && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(220, 220, 220, 0.5)"
                      }}
                    />
                  )}
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>


  );
};

export default CardNavigator;