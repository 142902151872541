import React, {useState, useEffect} from 'react';
import {Typography, Radio, RadioGroup, FormControlLabel, FormControl, Paper, Box} from '@mui/material';


const ScoreMessage = ({answers}) => {
  const getScore = () => {
    const ans = Object.values(answers);
    return ans.filter(answer => answer.isCorrect).length / ans.length * 100;
  };

  const score = getScore();

  const getScoreMessage = () => {
    if (score === 100) return `${score}% 🎉 Perfect score! Keep up the great work! 🎉`;
    if (score >= 80) return `${score}% 👏 Almost there! Keep practicing! 👏`;
    return `${score}% 👍 Good effort! Practice more to improve your score! 👍`;
  };
  return (
    <Typography variant="h4" align="center"
                color={score === 100 ? "success.main" : score >= 80 ? "warning.main" : "error.main"}>
      {getScoreMessage()}
    </Typography>
  )
}

const Quiz = ({data, appUrl, redirectUrl}) => {
  const [answers, setAnswers] = useState({});

  useEffect(() => {
    setAnswers({});
  }, [data])

  const handleChoiceChange = (questionId, choiceId, isCorrect) => {
    setAnswers(prev => ({...prev, [questionId]: {choiceId, isCorrect}}));
  };

  return (
    <div>
      {data ? (
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
          {data.questions.map(question => (
            <Paper key={question.id} sx={{width: '100%', marginBottom: 2, padding: 2}}>
              <Typography variant="h6">{question.text}</Typography>
              <FormControl component="fieldset" disabled={answers[question.id]}>
                <RadioGroup
                  onChange={(e) => handleChoiceChange(question.id, parseInt(e.target.value), question.choices.find(choice => choice.id === parseInt(e.target.value)).is_correct)}>
                  {question.choices.map(choice => (
                    <FormControlLabel
                      key={choice.id}
                      value={choice.id}
                      control={<Radio/>}
                      label={choice.text}
                      sx={{
                        backgroundColor: answers[question.id] && !answers[question.id].isCorrect && choice.is_correct ? 'rgba(0, 255, 0, 0.1)' : 'transparent',
                        fontWeight: answers[question.id] && !answers[question.id].isCorrect && choice.is_correct ? 'bold' : 'normal',
                        '&:hover': {
                          backgroundColor: answers[question.id] && !answers[question.id].isCorrect && choice.is_correct ? 'rgba(0, 255, 0, 0.2)' : 'transparent',
                        },
                        borderRadius: 2,
                        marginY: 1,
                        paddingX: 1
                      }}/>
                  ))}
                </RadioGroup>
                {answers[question.id] && (
                  <Typography color={answers[question.id].isCorrect ? "success.main" : "error.main"}>
                    {answers[question.id].isCorrect ? "Correct" : "Incorrect"}
                  </Typography>
                )}
              </FormControl>
            </Paper>
          ))}
          {Object.keys(answers).length === data.questions.length && (
            <ScoreMessage answers={answers}/>
          )}
        </Box>
      ) : (
        <Typography>Loading...</Typography>
      )}
    </div>
  );
};


export default Quiz;
