import React, {useState} from 'react'
import Collection from "../components/Collection";

function ExploreCollection({appUrl}) {
  return (
    <Collection appUrl={appUrl}/>
  )
}

export default ExploreCollection;

