import React from 'react';
import {useNavigate} from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Paper,
  Grid,
  Button
} from '@mui/material';
import textsData from "../configs/texts.json";

const Home = () => {
  const navigate = useNavigate();
  return (
    <Container maxWidth="md">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          padding: '2rem',
          backgroundColor: 'background.default',
        }}
      >
        <Typography variant="h2" component="h1" gutterBottom>
          {textsData.Home.headline}
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          {textsData.Home.subHeadline}
        </Typography>


        <Box
          component={Paper}
          sx={{
            marginTop: '2rem',
            padding: '2rem',
            marginBottom: '1rem',
            backgroundColor: 'rgba(255, 255, 255, 0.85)',
            borderRadius: '1rem',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <Typography variant="h6" gutterBottom>
                  {textsData.Home.bodyTitle1}
                </Typography>
                <Typography paragraph>
                  {textsData.Home.bodyContent1}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{alignSelf: 'start'}}
                  onClick={() => {navigate('/create');}}
                >
                  Try now!
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                alt=""
                src="https://storage.googleapis.com/genejoy-pub/genejoy_2_bg.png"
                sx={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: '1rem',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Grid container spacing={2}>
          {[2, 3, 4].map((i) => (
            <Grid item xs={12} md={4} key={i}>
              <Box
                component={Paper}
                sx={{
                  height: '100%',
                  padding: '2rem',
                  backgroundColor: 'rgba(255, 255, 255, 0.85)',
                  borderRadius: '1rem',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              >
                <Typography variant="h6" gutterBottom>
                  {textsData.Home[`bodyTitle${i}`]}
                </Typography>
                <Typography paragraph>
                  {textsData.Home[`bodyContent${i}`]}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Home;
