import React from 'react';
import CardNavigator from "../components/CardNavigator";


const Explore = () => {
  const options = [
    {type: 'Games', url: '/explore/games', img: '/static/game.png'},
    {type: 'Stories', url: '/explore/stories', img: '/static/story.png'},
    {type: 'Riddles', url: '/explore/riddles', img: '/static/riddle.png'},
    {type: 'Quizzes', url: '/explore/quizzes', img: '/static/quiz.png'},
    {type: 'Images', url: '/explore/images', img: '/static/image.png', disabled: true},
    {type: 'Encyclopedia', url: '/explore/encyclopedia', img: '/static/encyclopedia.png', disabled: true},
  ];

  return (
    <CardNavigator
      title="Click to explore..."
      options={options}
    />
  )
}

export default Explore;
