import {
  Card,
  CardHeader,
  CardContent,
} from '@mui/material';
import ShareButton from "./ShareButton";


function TextCard({data, appUrl, redirectUrl}) {
  return (
    <Card>
      <CardHeader title={data.title}/>
      <CardContent sx={{'white-space': 'pre-wrap'}}>
        {data.content}
      </CardContent>
      <ShareButton
        patchUrl={`${appUrl}${data.id}/`}
        redirectUrl={redirectUrl}
      />
    </Card>
  );
}

export default TextCard;