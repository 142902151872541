import React from 'react';
import {AppBar, Toolbar, Typography, Button, Box} from '@mui/material';
import {Link} from 'react-router-dom';

const Navbar = () => {
  const linkStyles = {textTransform: 'none', marginRight: '1rem'}
  return (
    <AppBar position="static" elevation={0} sx={{backgroundColor: 'background.paper'}}>
      <Toolbar>
        <Typography
          variant="h6"
          component={Link}
          to="/"
          sx={{
            textDecoration: 'none',
            color: 'text.primary',
            flexGrow: 1,
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            component="img"
            alt="GeneJoy Logo"
            src="https://storage.googleapis.com/genejoy-pub/android-chrome-192x192.png"
            sx={{
              width: '2rem',
              height: 'auto',
              marginRight: '0.5rem',
            }}
          />
          GeneJoy!
        </Typography>
        <Button component={Link} to="/" color="inherit" sx={linkStyles}>
          Home
        </Button>
        <Button component={Link} to="/create" color="inherit" sx={linkStyles}>
          Create
        </Button>
        <Button component={Link} to="/explore" color="inherit" sx={linkStyles}>
          Explore
        </Button>
        <Button component={Link} to="/about" color="inherit" sx={linkStyles}>
          About
        </Button>
        <Button
          variant="contained"
          component={Link}
          // to="/join"
          to="https://discord.gg/k7HzQ7SH2b"
          color="secondary"
          sx={{textTransform: 'none', fontWeight: 'bold'}}
        >
          Join Us!
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
