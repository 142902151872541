import React from 'react';
import {Route, Routes} from 'react-router-dom';
import './App.css';
import Explore from "./pages/Explore";
import ExploreCollection from './pages/ExploreCollection';
import Create from './pages/Create';
import CreateForm from './pages/CreateForm';
import Quiz from "./components/Quiz";
import Home from './pages/Home';
import About from './pages/About';
import Navbar from './components/Navbar';
import { CssBaseline } from '@mui/material';

import gamesOptions from './configs/games/options.json';
import storiesOptions from './configs/stories/options.json';
import riddlesOptions from './configs/riddles/options.json';
import quizzesOptions from './configs/quizzes/options.json';
import imagesOptions from './configs/images/options.json';

import gamesTexts from './configs/games/texts.json';
import storiesTexts from './configs/stories/texts.json';
import riddlesTexts from './configs/riddles/texts.json';
import quizzesTexts from './configs/quizzes/texts.json';
import imagesTexts from './configs/images/texts.json';

function App() {
  return (
    <>
      <CssBaseline />
      <Navbar/>
      <Routes>
        <Route path="/" exact element={<Home/>}/>
        <Route path="/explore" element={<Explore/>}/>
        <Route path="/explore/games" element={<ExploreCollection appUrl="/api/games/"/>}/>
        <Route path="/explore/stories" element={<ExploreCollection appUrl="/api/stories/"/>}/>
        <Route path="/explore/riddles" element={<ExploreCollection appUrl="/api/riddles/"/>}/>
        <Route path="/explore/quizzes" element={<ExploreCollection appUrl="/api/quizzes/"/>}/>
        <Route path="/explore/images" element={<ExploreCollection appUrl="/api/images/"/>}/>
        <Route path="/create" element={<Create/>}/>
        <Route path="/create/game" element={<CreateForm texts={gamesTexts} appUrl="/api/games/" redirectUrl="/explore/games" optionsConfig={gamesOptions}/>}/>
        <Route path="/create/story" element={<CreateForm texts={storiesTexts} appUrl="/api/stories/" redirectUrl="/explore/stories" optionsConfig={storiesOptions}/>}/>
        <Route path="/create/riddle" element={<CreateForm texts={riddlesTexts} appUrl="/api/riddles/" redirectUrl="/explore/riddles" optionsConfig={riddlesOptions}/>}/>
        <Route path="/create/quiz" element={<CreateForm texts={quizzesTexts} appUrl="/api/quizzes/" redirectUrl="/explore/quizzes" optionsConfig={quizzesOptions}><Quiz/></CreateForm>}/>
        <Route path="/create/image" element={<CreateForm texts={imagesTexts} appUrl="/api/images/" redirectUrl="/explore/images" optionsConfig={imagesOptions}/>}/>
        <Route path="/about" element={<About/>}/>
      </Routes>
    </>
  );
}

export default App;