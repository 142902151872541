export function truncateString(str, maxLength) {
  // Return the original string if it's shorter than the max length
  if (!str) {
    return str;
  }
  if (str.length <= maxLength) {
    return str;
  }

  // Initialize the truncated string to an empty string
  let truncated = '';

  // Split the string into words
  let words = str.split(' ');

  // Iterate through words, adding them to the truncated string
  // as long as this doesn't make the truncated string exceed the max length
  for (let i = 0; i < words.length; i++) {
    // If adding the current word doesn't exceed the max length,
    // add the word and a space (if not the last word) to the truncated string
    if ((truncated.length + words[i].length + (i < words.length - 1 ? 1 : 0)) <= maxLength) {
      truncated += words[i];
      if (i < words.length - 1) {
        truncated += ' ';
      }
    } else {
      // If adding the current word would exceed the max length,
      // stop adding words and break out of the loop
      break;
    }
  }

  // Add an ellipsis to the end of the truncated string
  truncated += '...';

  // Return the truncated string
  return truncated;
}