import {createTheme} from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FFC107',
    },
    secondary: {
      main: '#FFA726',
    },
    tertiary: {
      main: '#26A69A',
    },
    background: {
      default: '#F5F5F5',
    },
    text: {
      primary: '#424242',
    },
  },
  typography: {
    fontFamily: "'Nunito', sans-serif",
    h1: {
      fontFamily: "'Roboto', sans-serif",
    },
    h2: {
      fontFamily: "'Roboto', sans-serif",
    },
    h3: {
      fontFamily: "'Roboto', sans-serif",
    },
    h4: {
      fontFamily: "'Roboto', sans-serif",
    },
    h5: {
      fontFamily: "'Roboto', sans-serif",
    },
    h6: {
      fontFamily: "'Roboto', sans-serif",
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          padding: 0,
          backgroundColor: '#F5F5F5',
        },
      },
    },
  },
});

export default theme;