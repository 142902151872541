import React, {useState, useEffect} from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  Typography,
  Grid,
  Box,
  Alert,
  Modal,
  TextField,
  Button
} from '@mui/material';
import {Favorite as FavoriteIcon} from '@mui/icons-material';
import apiConfig from '../configs/api.json';
import api from "../utils/api";
import {truncateString} from "../utils/string";
import moment from 'moment';


function Collection({appUrl}) {
  const [items, setItems] = useState([]);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

// Call this function when the "Edit" button is clicked
  const handleEditClick = () => {
    setIsEditing(true);
  };

// Call these functions when the text fields are changed
  const handleTitleChange = (e) => {
    setSelectedItem({...selectedItem, title: e.target.value});
  };

  const handleContentChange = (e) => {
    setSelectedItem({...selectedItem, content: e.target.value});
  };

// Call this function when the "Save" button is clicked
  const handleSaveClick = () => {
    api
      .patch(`${apiConfig.serverUrl}${appUrl}${selectedItem.id}/`, {
        title: selectedItem.title,
        content: selectedItem.content
      })
      .then(response => {
        // Update the items state
        const updatedItem = response.data;
        const newItems = items.map(item =>
          item.id === updatedItem.id ? updatedItem : item
        );
        setItems(newItems);
      })
      .catch(error => {
        setError(true);
      });
    setIsEditing(false);
  };

  useEffect(() => {
    api
      .get(`${apiConfig.serverUrl}${appUrl}`, {params: {shared: true}})
      .then(response => {
        setItems(response.data.results);
        setError(false);
      })
      .catch(error => {
        setError(true);
      })
  }, [appUrl]);

  const handleOpenModal = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
    setOpen(false);
  };

  const handleLikeClick = (item, index) => {
    api
      .post(`${apiConfig.serverUrl}${appUrl}${item.id}/like/`)
      .then(response => {
        const updatedItem = response.data;
        const newItems = [...items];
        newItems[index] = updatedItem;
        setItems(newItems);
        setError(false);
      })
      .catch(error => {
        setError(true);
      })
  };

  if (error) {
    return <Alert severity="error">Error! Try again later</Alert>
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        width: '100%',
        backgroundColor: 'background.default',
        paddingTop: '2rem',
      }}
    >
      <Grid container m={2} spacing={2} justifyContent="center">
        {items.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
            <Card sx={{width: '100%', borderRadius: '1rem'}}>
              <Box onClick={() => handleOpenModal(item)}>
                <CardHeader
                  title={item.title || truncateString(item.content, 24)}
                  subheader={moment(item.created_at).fromNow()}
                  titleTypographyProps={{fontFamily: 'Nunito, sans-serif', color: 'primary.main'}}
                  subheaderTypographyProps={{fontFamily: 'Nunito, sans-serif'}}
                />
              </Box>
              <CardActions disableSpacing>
                <IconButton
                  aria-label="add to favorites"
                  onClick={() => handleLikeClick(item, index)}
                >
                  <FavoriteIcon
                    {...(item.likes_count ? {style: {color: 'red'}} : {})}
                  />
                </IconButton>
                <Typography variant="body2" sx={{paddingLeft: 1}}>
                  {item.likes_count} likes
                </Typography>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: '1rem',
            p: 4,
            width: '80%',
            maxWidth: '600px',
          }}
        >
          {selectedItem && (
            <>
              <Typography
                id="modal-title"
                variant="h5"
                component="h2"
                mb={2}
                sx={{
                  fontFamily: (theme) => theme.typography.h5.fontFamily,
                }}
              >
                {isEditing ? (
                  <TextField fullWidth value={selectedItem.title} onChange={handleTitleChange}/>
                ) : (
                  selectedItem.title
                )}
              </Typography>
              <Box
                sx={{
                  maxHeight: '60vh',
                  overflowY: 'auto',
                  "white-space": "pre-wrap",
                  '&::-webkit-scrollbar': {
                    width: '0.4em',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: (theme) => theme.palette.secondary.main,
                    borderRadius: '1rem',
                  },
                }}
              >
                <Typography
                  id="modal-description"
                  variant="body1"
                  sx={{
                    fontFamily: (theme) => theme.typography.fontFamily,
                    color: (theme) => theme.palette.text.primary,
                  }}
                >
                  {isEditing ? (
                    <TextField
                      value={selectedItem.content}
                      onChange={handleContentChange}
                      multiline
                      fullWidth
                    />
                  ) : (
                    selectedItem.content
                  )}
                </Typography>
              </Box>
              {isEditing ? (
                <Button onClick={handleSaveClick}>Save</Button>
              ) : (
                <Button onClick={handleEditClick}>Edit</Button>
              )}
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
}

export default Collection;

