import React from 'react';
import GenericForm from "../components/Form";
import TextCard from "../components/TextCard";

function CreateForm({texts, appUrl, redirectUrl, optionsConfig, children}) {

  const ChildComponent = children || <TextCard />;

  return (
    <GenericForm
      title={texts.title}
      optionsConfig={optionsConfig}
      appUrl={appUrl}
      redirectUrl={redirectUrl}
    >
      {props => React.cloneElement(ChildComponent, props)}
    </GenericForm>
  );
}

CreateForm.defaultProps = {
  children: <TextCard />,
};

export default CreateForm;
