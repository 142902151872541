import React from 'react';
import {Box, Container, Paper, Typography} from '@mui/material';

const About = () => {
  return (
    <Container maxWidth="md">
      <Box marginTop="2rem">
        <Typography variant="h3" gutterBottom>
          Why GeneJoy?
        </Typography>
        <Box
          component={Paper}
          sx={{
            marginTop: '1rem',
            padding: '2rem',
            backgroundColor: 'rgba(255, 255, 255, 0.85)',
            borderRadius: '1rem',
          }}
        >

          <Typography paragraph>
            At GeneJoy, we believe that strong family bonds are the foundation for a thriving humanity, contributing to
            happiness and well-being around the world. We envision a future where technology serves to enhance human
            joy, and with this in mind, our mission is to inspire families through knowledge-sharing between parents and
            children, while elevating daily parenting with engaging, game-based educational experiences for kids.
          </Typography>
          <Typography paragraph>
            Our dedication to creating positive experiences and nurturing generative joy goes hand-in-hand with building
            a community that strengthens family bonds and promotes growth. The essence of our mission is captured in our
            name, which symbolizes the transmission of joy across generations, much like the way genes are passed down
            through family lines. By developing and sharing engaging educational experiences, we aspire to build a world
            where the joy of learning is woven into the fabric of our lives, connecting parents and children, and
            empowering them to shape a brighter future together.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default About;
